
export const price1 = [
    {
      "index": 1,
      "value": "MARGHARITA ",
      "category": "PRISKLASS 1",
      "price": "100",
      "familyPrice": "240",
      "ingredients": [
        {
          "id": "tomat"
        },
        {
          "id": "ost"
        }
      ]
    },
    {
      "index": 2,
      "value": "VESUVIO",
      "category": "PRISKLASS 1",
      "price": "100",
      "familyPrice": "240",
      "ingredients": [
        {
          "id": "skinka"
        }
      ]
    },
    {
      "index": 3,
      "value": "AL FUNGHI",
      "category": "PRISKLASS 1",
      "price": "100",
      "familyPrice": "240",
      "ingredients": [
        {
          "id": "färska champinjoner"
        }
      ]
    }
]

export const price2 = [
    {
      "index": 4,
      "value": "HAWAII",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "ananas" }
      ]
    },
    {
      "index": 5,
      "value": "CAPRICCIOSA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" }
      ]
    },
    {
      "index": 6,
      "value": "POMPEJ",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "bacon" }
      ]
    },
    {
      "index": 7,
      "value": "TOMASO",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "räkor" }
      ]
    },
    {
      "index": 8,
      "value": "RUSTIKA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "salami" }
      ]
    },
    {
      "index": 9,
      "value": "SALAMI",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "salami" },
        { "id": "lök" },
        { "id": "paprika" }
      ]
    },
    {
      "index": 10,
      "value": "PEPPERONI",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "pepperonikorv" }
      ]
    },
    {
      "index": 11,
      "value": "ÄGGPIZZA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "bacon" },
        { "id": "ägg" },
        { "id": "lök" }
      ]
    },
    {
      "index": 12,
      "value": "VEGETARIANA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "champinjoner" },
        { "id": "lök" },
        { "id": "paprika" },
        { "id": "sparris" },
        { "id": "oliver" },
        { "id": "kronärtskocka" }
      ]
    },
    {
      "index": 13,
      "value": "CAPRIANA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" },
        { "id": "ananas" }
      ]
    },
    {
      "index": 14,
      "value": "KIK SPECIAL",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "banan" },
        { "id": "jordnötter" },
        { "id": "ananas" },
        { "id": "curry" }
      ]
    },
    {
      "index": 15,
      "value": "AFRIKANA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "banan" },
        { "id": "jordnötter" },
        { "id": "ananas" },
        { "id": "curry" }
      ]
    },
    {
      "index": 16,
      "value": "ATHENA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "pepperonikorv" },
        { "id": "paprika" },
        { "id": "vitost" }
      ]
    },
    {
      "index": 17,
      "value": "ANNA SPECIAL",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" },
        { "id": "ananas" },
        { "id": "räkor" }
      ]
    },
    {
      "index": 18,
      "value": "RIMINI",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" },
        { "id": "räkor" }
      ]
    },
    {
      "index": 19,
      "value": "PRINSESSA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "ananas" },
        { "id": "räkor" }
      ]
    },
    {
      "index": 20,
      "value": "QUATTRO",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" },
        { "id": "räkor" },
        { "id": "musslor" },
        { "id": "kronärtskocka" }
      ]
    },
    {
      "index": 21,
      "value": "HAVETS",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "tonfisk" },
        { "id": "räkor" },
        { "id": "musslor" },
        { "id": "kräftstjärtar" }
      ]
    },
    {
      "index": 22,
      "value": "MARINARA",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "räkor" },
        { "id": "musslor" }
      ]
    },
    {
      "index": 23,
      "value": "TONFISK",
      "category": "PIZZA KLASS 2",
      "price": "110",
      "familyPrice": "260",
      "ingredients": [
        { "id": "tonfisk" },
        { "id": "champinjoner" },
        { "id": "lök" }
      ]
    }
]

export const price3 = [
    {
      "index": 24,
      "value": "MEXICANA",
      "category": "PIZZA KLASS 3",
      "price": "115",
      "familyPrice": "270",
      "ingredients": [
        { "id": "köttfärs" },
        { "id": "tacokryddmix" },
        { "id": "vitlök" },
        { "id": "lök" },
        { "id": "tacosås" },
        { "id": "jalapeno" }
      ]
    },
    {
      "index": 25,
      "value": "CARUSO",
      "category": "PIZZA KLASS 3",
      "price": "115",
      "familyPrice": "270",
      "ingredients": [
        { "id": "skinka" },
        { "id": "köttfärs" },
        { "id": "bacon" },
        { "id": "kebab" },
        { "id": "feferoni" },
        { "id": "vitlökssås" }
      ]
    },
    {
      "index": 26,
      "value": "ROYAL",
      "category": "PIZZA KLASS 3",
      "price": "115",
      "familyPrice": "270",
      "ingredients": [
        { "id": "skinka" },
        { "id": "salami" },
        { "id": "bacon" },
        { "id": "lök" }
      ]
    },
    {
      "index": 27,
      "value": "AZTEKA",
      "category": "PIZZA KLASS 3",
      "price": "115",
      "familyPrice": "270",
      "ingredients": [
        { "id": "skinka" },
        { "id": "tacokryddmix" },
        { "id": "tacosås" },
        { "id": "jalapeno" },
        { "id": "tzatzikisås" }
      ]
    },
    {
      "index": 28,
      "value": "VERONIKA SPECIAL",
      "category": "PIZZA KLASS 3",
      "price": "115",
      "familyPrice": "270",
      "ingredients": [
        { "id": "köttfärs" },
        { "id": "ananas" },
        { "id": "färsk vitlök" }
      ]
    },
    {
      "index": 29,
      "value": "BOLOGNESE",
      "category": "PIZZA KLASS 3",
      "price": "115",
      "familyPrice": "270",
      "ingredients": [
        { "id": "köttfärssås" },
        { "id": "lök" }
      ]
    }
]

export const price4 = [
    {
      "index": 30,
      "value": "LASSES SPECIAL",
      "category": "PIZZA KLASS 4",
      "price": "120",
      "familyPrice": "280",
      "ingredients": [
        { "id": "champinjoner" },
        { "id": "bacon" },
        { "id": "lök" },
        { "id": "ägg" },
        { "id": "färsk vitlök" },
        { "id": "stark korv" },
        { "id": "tacosås" }
      ]
    },
    {
      "index": 31,
      "value": "MAMA ROSA",
      "category": "PIZZA KLASS 4",
      "price": "120",
      "familyPrice": "280",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" },
        { "id": "köttfärs" },
        { "id": "stark korv" },
        { "id": "paprika" },
        { "id": "bacon" }
      ]
    },
    {
      "index": 32,
      "value": "ANDRE SPECIAL",
      "category": "PIZZA KLASS 4",
      "price": "120",
      "familyPrice": "280",
      "ingredients": [
        { "id": "skinka" },
        { "id": "champinjoner" },
        { "id": "räkor" },
        { "id": "köttfärs" },
        { "id": "sparris" },
        { "id": "cayennepeppar" }
      ]
    }
]

export const price5 = [
  {
    "index": 33,
    "value": "KYCKLINGPIZZA",
    "category": "PIZZA KLASS 5",
    "info": "Valfri sås ingår",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 34,
    "value": "LEGEND",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "champinjoner" },
      { "id": "tomat" },
      { "id": "lök" },
      { "id": "curry" }
    ]
  },
  {
    "index": 35,
    "value": "TROPICANA",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "ananas" },
      { "id": "banan" },
      { "id": "nötter" },
      { "id": "curry" }
    ]
  },
  {
    "index": 36,
    "value": "CICILLIA",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "tomater" },
      { "id": "paprika" },
      { "id": "lök" },
      { "id": "feferoni" }
    ]
  },
  {
    "index": 37,
    "value": "VESUVIO SPECIAL",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "skinka" },
      { "id": "bacon" }
    ]
  },
  {
    "index": 38,
    "value": "RONDELL SPECIAL",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "kebab" },
      { "id": "sallad" },
      { "id": "lök" },
      { "id": "tomater" },
      { "id": "gurka" },
      { "id": "feferoni" },
      { "id": "pommes" }
    ]
  },
  {
    "index": 39,
    "value": "AMIGO",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "kebab" },
      { "id": "tacokryddmix" },
      { "id": "vitlök" },
      { "id": "paprika" },
      { "id": "tacosås" },
      { "id": "jalapeno" }
    ]
  },
  {
    "index": 40,
    "value": "HACKADE PIZZA",
    "category": "PIZZA KLASS 5",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "kebab (nöt)" },
      { "id": "champinjoner" },
      { "id": "oliver" },
      { "id": "lök" },
      { "id": "jalapeno" },
      { "id": "feferoni" },
      { "id": "paprika" }
    ]
  }
]

export const oxfile = [
  {
    "index": 46,
    "value": "ATLANTIC",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "tomat" },
      { "id": "sparris" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 47,
    "value": "DALLAS",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "champinjoner" },
      { "id": "tomat" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 48,
    "value": "LYXPIZZA",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "skinka" },
      { "id": "champinjoner" },
      { "id": "bacon" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 49,
    "value": "ELINA SPECIAL",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "fläskfilé" },
      { "id": "champinjoner" },
      { "id": "lök" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 50,
    "value": "ACAPULCO",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "champinjoner" },
      { "id": "lök" },
      { "id": "tacokryddmix" },
      { "id": "tacosås" },
      { "id": "färsk vitlök" },
      { "id": "jalapeno" }
    ]
  },
  {
    "index": 51,
    "value": "SWEDEN",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "fläskfilé" },
      { "id": "skinka" },
      { "id": "räkor" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 52,
    "value": "SKÅNE",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "fläskfilé" },
      { "id": "bacon" },
      { "id": "tomat" },
      { "id": "lök" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 53,
    "value": "GORGONZOLA",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "fläskfilé" },
      { "id": "champinjoner" },
      { "id": "tomat" },
      { "id": "lök" },
      { "id": "gorgonzola" }
    ]
  },
  {
    "index": 54,
    "value": "RÖGLE SPECIAL",
    "category": "OXFILEPIZZOR",
    "price": "125",
    "familyPrice": "290",
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "kyckling" },
      { "id": "paprika" },
      { "id": "bearnaisesås" }
    ]
  }
]

export const kebabPizzor = [
  {
    "index": 41,
    "value": "KEBABPIZZA",
    "category": "KEBABPIZZOR",
    "price": "120",
    "familyPrice": "280",
    "certainPrice": "115:- / 270:-",
    "ingredients": [
      { "id": "kebab" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 42,
    "value": "KEBABPIZZA SPECIAL",
    "category": "KEBABPIZZOR",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kebab" },
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "lök" },
      { "id": "gurka" },
      { "id": "feferoni" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 43,
    "value": "LA BAMBA",
    "category": "KEBABPIZZOR",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kebab" },
      { "id": "skinka" },
      { "id": "bacon" },
      { "id": "feferoni" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 44,
    "value": "SNICKARE SPECIAL",
    "category": "KEBABPIZZOR",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kebab" },
      { "id": "skinka" },
      { "id": "lök" },
      { "id": "pommes" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 45,
    "value": "LANDSKRONA SPECIAL",
    "category": "KEBABPIZZOR",
    "price": "120",
    "familyPrice": "280",
    "ingredients": [
      { "id": "kebab/gyros" },
      { "id": "skinka" },
      { "id": "champinjoner" },
      { "id": "tomater" },
      { "id": "stark korv" },
      { "id": "lök" }
    ]
  }
]

export const mozzarellaPizzor = [
  {
    "index": 55,
    "value": "MAMMAS PIZZA",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "champinjoner" },
      { "id": "lufttorkad skinka" },
      { "id": "pesto" },
      { "id": "ruccola" }
    ]
  },
  {
    "index": 56,
    "value": "RUCCOLA",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "champinjoner" },
      { "id": "paprika" },
      { "id": "gorgonzola" },
      { "id": "lufttorkad skinka" },
      { "id": "pesto" },
      { "id": "ruccola" }
    ]
  },
  {
    "index": 57,
    "value": "FORMAGGI",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "gorgonzola" },
      { "id": "vitost" },
      { "id": "ruccola" }
    ]
  },
  {
    "index": 58,
    "value": "DELICASSA SPECIALE",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "salami" },
      { "id": "lufttorkad skinka" },
      { "id": "pesto" },
      { "id": "ruccola" }
    ]
  },
  {
    "index": 59,
    "value": "TOSCANA",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "lufttorkad skinka" },
      { "id": "soltorkade tomater" },
      { "id": "grillad paprika" },
      { "id": "pesto" },
      { "id": "ruccola" }
    ]
  },
  {
    "index": 60,
    "value": "PARMA SPECIAL",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "körsbärstomater" },
      { "id": "oliver" },
      { "id": "vitost" },
      { "id": "färsk vitlök" },
      { "id": "lufttorkad skinka" },
      { "id": "ruccola" }
    ]
  },
  {
    "index": 61,
    "value": "NUTELLA",
    "category": "MOZZARELLAPIZZOR",
    "price": "130",
    "familyPrice": "300",
    "ingredients": [
      { "id": "mozzarella" },
      { "id": "banan" },
      { "id": "nutella" }
    ]
  }
]
  
export const amerikanska = [
  {
    "index": 62,
    "value": "CHICAGO",
    "category": "AMERIKANSKA PIZZOR",
    "price": "135",
    "familyPrice": null,
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "bacon" },
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 63,
    "value": "HOLLYWOOD",
    "category": "AMERIKANSKA PIZZOR",
    "price": "135",
    "familyPrice": null,
    "ingredients": [
      { "id": "fläskfilé" },
      { "id": "champinjoner" },
      { "id": "lök" },
      { "id": "bacon" },
      { "id": "tzatzikisås" }
    ]
  },
  {
    "index": 64,
    "value": "AMERICAN DREAM",
    "category": "AMERIKANSKA PIZZOR",
    "price": "135",
    "familyPrice": null,
    "ingredients": [
      { "id": "kyckling" },
      { "id": "purjolök" },
      { "id": "paprika" },
      { "id": "bacon" }
    ]
  },
  {
    "index": 65,
    "value": "NEW YORK",
    "category": "AMERIKANSKA PIZZOR",
    "price": "135",
    "familyPrice": null,
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "pepperonikorv" },
      { "id": "lök" },
      { "id": "paprika" },
      { "id": "tomat" },
      { "id": "jalapeno" },
      { "id": "tacokryddmix" },
      { "id": "tacosås" }
    ]
  },
  {
    "index": 66,
    "value": "ARIZONA",
    "category": "AMERIKANSKA PIZZOR",
    "price": "135",
    "familyPrice": null,
    "ingredients": [
      { "id": "skinka" },
      { "id": "bacon" },
      { "id": "salami" },
      { "id": "gorgonzola" },
      { "id": "tomat" },
      { "id": "lök" }
    ]
  }
]

export const halvinbakade = [
  {
    "index": 67,
    "value": "VIKINGBÅT",
    "category": "HALVINBAKADE PIZZOR",
    "price": "120",
    "familyPrice": null,
    "ingredients": [
      { "id": "kebab" },
      { "id": "skinka" },
      { "id": "tomater" },
      { "id": "lök" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 68,
    "value": "CIAO CIAO",
    "category": "HALVINBAKADE PIZZOR",
    "price": "120",
    "familyPrice": null,
    "ingredients": [
      { "id": "oxfilé" },
      { "id": "champinjoner" },
      { "id": "tomat" },
      { "id": "lök" },
      { "id": "valfri sås" }
    ]
  },
  {
    "index": 69,
    "value": "FAVORIT",
    "category": "HALVINBAKADE PIZZOR",
    "price": "120",
    "familyPrice": null,
    "ingredients": [
      { "id": "fläskfilé" },
      { "id": "champinjoner" },
      { "id": "tomat" },
      { "id": "lök" },
      { "id": "valfri sås" }
    ]
  }
]

export const inbakade = [
  {
    "index": 70,
    "value": "CALZONE",
    "category": "INBAKADE PIZZOR",
    "price": "110",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "skinka" }
    ]
  },
  {
    "index": 71,
    "value": "GOOL",
    "category": "INBAKADE PIZZOR",
    "price": "115",
    "certainPrice": "115:-",
    "ingredients": [
      { "id": "skinka" },
      { "id": "kebab" }
    ]
  },
  {
    "index": 72,
    "value": "KUNG CALZONE",
    "category": "INBAKADE PIZZOR",
    "price": "115",
    "certainPrice": "115:-",
    "ingredients": [
      { "id": "skinka" },
      { "id": "champinjoner" },
      { "id": "räkor" }
    ]
  },
  {
    "index": 73,
    "value": "KEBAB CALZONE",
    "category": "INBAKADE PIZZOR",
    "price": "120",
    "certainPrice": "120:-",
    "ingredients": [
      { "id": "kebab" },
      { "id": "champinjoner" },
      { "id": "lök" }
    ]
  },
  {
    "index": 74,
    "value": "FLÄSKFILÉ CALZONE",
    "category": "INBAKADE PIZZOR",
    "price": "120",
    "certainPrice": "120:-",
    "ingredients": [
      { "id": "fläskfilé" },
      { "id": "champinjoner" },
      { "id": "lök" },
      { "id": "färsk vitlök" }
    ]
  },
  {
    "index": 75,
    "value": "UFO (överbakad)",
    "category": "INBAKADE PIZZOR",
    "price": "130",
    "certainPrice": "130:-",
    "ingredients": [
      { "id": "skinka" },
      { "id": "champinjoner" },
      { "id": "räkor" }
    ]
  }
]

export const allProductsData = price1.concat(price2, price3, price4, price5, oxfile, 
  kebabPizzor, mozzarellaPizzor, amerikanska, halvinbakade, inbakade)

export const allKebabMeny = [
  {
    "index": 1,
    "value": "KEBABTALLRIK",
    "category": "Kebabmeny",
    "info": "Isbergssallad, tomat, gurka, lök och feferoni ingår",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "kebab" },
      { "id": "pommes" }
    ]
  },
  {
    "index": 2,
    "value": "KEBABRULLE",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "kebab" }
    ]
  },
  {
    "index": 3,
    "value": "KEBAB MED BRÖD",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "kebab" }
    ]
  },
  {
    "index": 4,
    "value": "KEBABTALLRIK DELUXE",
    "category": "Kebabmeny",
    "certainPrice": "120:-",
    "ingredients": [
      { "id": "kebab" },
      { "id": "pommes" },
      { "id": "vitost" }
    ]
  },
  {
    "index": 5,
    "value": "KYCKLINGTALLRIK",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "pommes" }
    ]
  },
  {
    "index": 6,
    "value": "KYCKLINGRULLE",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "kyckling" }
    ]
  },
  {
    "index": 7,
    "value": "KYCKLING MED BRÖD",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      { "id": "kyckling" }
    ]
  },
  {
    "index": 8,
    "value": "MIXTALLRIK",
    "category": "Kebabmeny",
    "certainPrice": "120:-",
    "ingredients": [
      { "id": "kyckling" },
      { "id": "kebab" },
      { "id": "pommes" }
    ]
  },
  {
    "index": 9,
    "value": "FALAFELTALLRIK",
    "category": "Kebabmeny",
    "certainPrice": "100:-",
    "ingredients": [
      { "id": "falafel" }
    ]
  },
  {
    "index": 10,
    "value": "FALAFELRULLE",
    "category": "Kebabmeny",
    "certainPrice": "95:-",
    "ingredients": [
      { "id": "falafel" }
    ]
  },
  {
    "index": 11,
    "value": "FALAFEL I PITABRÖD",
    "category": "Kebabmeny",
    "certainPrice": "95:-",
    "ingredients": [
      { "id": "falafel" }
    ]
  },
  {
    "index": 12,
    "value": "KÖTTBULLAR",
    "category": "Kebabmeny",
    "certainPrice": "(6st) 60:- / (10st) 85:-",
    "ingredients": [
      { "id": "pommes" },
      { "id": "lingon" }
    ]
  },
  {
    "index": 13,
    "value": "CHICKEN NUGGETS",
    "category": "Kebabmeny",
    "certainPrice": "(4st) 55:-, (6st) 80:-, (8st) 90:-",
    "ingredients": []
  },
  {
    "index": 14,
    "value": "CHILI CHEESE",
    "category": "Kebabmeny",
    "certainPrice": "(4st) 35:-",
    "ingredients": []
  },
  {
    "index": 15,
    "value": "POMMES",
    "category": "Kebabmeny",
    "certainPrice": "50:-",
    "ingredients": []
  }
]

export const allSallad = [
  {
    "index": 1,
    "value": "OST & SKINKSALLAD",
    "category": "SALLADER",
    "price": "110",
    "info": "Isbergssallad, tomat, gurka, lök, majs och bröd ingår. Valfri sås i burk ingår.",
    "baseIngredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "lök" },
      { "id": "majs" },
      { "id": "bröd" }
    ],
    "ingredients": [
      { "id": "skinka" },
      { "id": "ost" },
      { "id": "ananas" }
    ]
  },
  {
    "index": 2,
    "value": "KYCKLINGSALLAD",
    "category": "SALLADER",
    "price": "110",
    "baseIngredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "lök" },
      { "id": "majs" },
      { "id": "bröd" }
    ],
    "ingredients": [
      { "id": "kyckling" }
    ]
  },
  {
    "index": 3,
    "value": "KEBABSALLAD",
    "category": "SALLADER",
    "price": "110",
    "baseIngredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "lök" },
      { "id": "majs" },
      { "id": "bröd" }
    ],
    "ingredients": [
      { "id": "kebab" }
    ]
  },
  {
    "index": 4,
    "value": "LANDSKRONASALLAD",
    "category": "SALLADER",
    "price": "110",
    "baseIngredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "lök" },
      { "id": "majs" },
      { "id": "bröd" }
    ],
    "ingredients": [
      { "id": "kyckling" },
      { "id": "kebab" },
      { "id": "ost" }
    ]
  },
  {
    "index": 5,
    "value": "RONDELLSALLAD",
    "category": "SALLADER",
    "price": "110",
    "baseIngredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "lök" },
      { "id": "majs" },
      { "id": "bröd" }
    ],
    "ingredients": [
      { "id": "kebab" },
      { "id": "skinka" },
      { "id": "ananas" },
      { "id": "ost" }
    ]
  },
  {
    "index": 6,
    "value": "RÄKSALLAD",
    "category": "SALLADER",
    "price": "110",
    "baseIngredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "lök" },
      { "id": "majs" },
      { "id": "bröd" }
    ],
    "ingredients": [
      { "id": "räkor" },
      { "id": "ägg" },
      { "id": "citron" }
    ]
  },
  {
    "index": 7,
    "value": "KEBAB",
    "category": "BAKAD POTATIS",
    "price": "110",
    "ingredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "smör" },
      { "id": "sås" },
      { "id": "kebab" }
    ]
  },
  {
    "index": 8,
    "value": "KYCKLING",
    "category": "BAKAD POTATIS",
    "price": "110",
    "ingredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "smör" },
      { "id": "sås" },
      { "id": "kyckling" }
    ]
  },
  {
    "index": 9,
    "value": "RÄKOR & ÄGG",
    "category": "BAKAD POTATIS",
    "price": "110",
    "ingredients": [
      { "id": "isbergssallad" },
      { "id": "tomat" },
      { "id": "gurka" },
      { "id": "smör" },
      { "id": "sås" },
      { "id": "räkor" },
      { "id": "ägg" }
    ]
  }
]

export const allALaCarte = [
  {
    "index": 1,
    "value": "LÖVBIFF",
    "category": "A LA CARTE",
    "info": "Välj mellan pommes eller stekt potatis. Serveras med isbergssallad, tomater och gruka",
    "price": "120",
    "ingredients": [
      { "id": "bearnaisesås" }
    ]
  },
  {
    "index": 2,
    "value": "RÖDSPÄTTA",
    "category": "A LA CARTE",
    "price": "120",
    "ingredients": [
      { "id": "remouladesås" },
      { "id": "citron" }
    ]
  },
  {
    "index": 3,
    "value": "SCHNITZEL",
    "category": "A LA CARTE",
    "price": "120",
    "ingredients": [
      { "id": "bearnaisesås" }
    ]
  },
    {
      "index": 4,
      "value": "LANGOS 1",
      "category": "LANGOS",
      "info": "Välj mellan mild creme fraiche, vitlökssås eller stark sås",
      "price": 100,
      "ingredients": [
        { "id": "ost" },
        { "id": "skinka" },
        { "id": "lök" }
      ]
    },
    {
      "index": 5,
      "value": "LANGOS 2",
      "category": "LANGOS",
      "price": 100,
      "ingredients": [
        { "id": "ost" },
        { "id": "skinka" },
        { "id": "lök" },
        { "id": "ananas" }
      ]
    },
    {
      "index": 6,
      "value": "LANGOS 3",
      "category": "LANGOS",
      "price": 100,
      "ingredients": [
        { "id": "ost" },
        { "id": "räkor" },
        { "id": "lök" },
        { "id": "rom" }
      ]
    },
    {
      "index": 7,
      "value": "LANGOS 4",
      "category": "LANGOS",
      "price": 100,
      "ingredients": [
        { "id": "ost" },
        { "id": "kyckling" },
        { "id": "isbergssallad" },
        { "id": "tomat" },
        { "id": "gurka" },
        { "id": "lök" }
      ]
    },
    {
      "index": 8,
      "value": "LANGOS 5",
      "category": "LANGOS",
      "price": 100,
      "ingredients": [
        { "id": "ost" },
        { "id": "kebab" },
        { "id": "isbergssallad" },
        { "id": "tomat" },
        { "id": "gurka" },
        { "id": "lök" }
      ]
    },
    {
      "index": 9,
      "value": "Hamburgare 90g",
      "category": "HAMBURGERMENY",
      "info": "Till alla hamburgare ingår sallad, dressing, lök & tomat. Extra: Ost +10:- Bacon 15:-",
      "certainPrice": "Bröd 75:-, Meny 95:-",
      "ingredients": []
    },
    {
      "index": 10,
      "value": "Hamburgare 150g",
      "category": "HAMBURGERMENY",
      "certainPrice": "Bröd 85:-, Many 105:-",
      "ingredients": []
    },
    {
      "index": 11,
      "value": "Hamburgare 200g",
      "category": "HAMBURGERMENY",
      "certainPrice": "Bröd 100:-, Many 120:-",
      "ingredients": []
    }
]