import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Header from './routes/landing/Header.js'
import {
  RootSection,
  Menu,
} from './routes/index'
import { allKebabMeny, allProductsData, allALaCarte, allSallad } from './data/AllProductsData.js'
import { allKebabProductsData } from './data/AllProductsData.js'
import { AllCategories } from './data/AllCategories'
import Kebab from './routes/kebab/Kebab.js'
import ALaCarte from './routes/alacarte/Alacarte.js'
import Salad from './routes/salad/Salad.js'

export default class App extends React.Component {
  constructor() {
    super()
    this.state = {
      allCategories: [],
      activeCategory: 'Pizzor',
      allProducts: [],
      allKebabProducts: [],
      allALaCarteProducts: [],
      allSaladProducts: []
    }

    this.getProductsByCategory = this.getProductsByCategory.bind(this)
    this.changeCategory = this.changeCategory.bind(this)
    this.removeNavigationMenu = this.removeNavigationMenu.bind(this)
    this.findMenuItem = this.findMenuItem.bind(this)
  }

  // GET DATA
  /*******************************************************/
  allCategoriesData = new Promise((resolve, reject) => {
    if (true) {
      resolve(AllCategories)
      return
    }
    reject('error, check the code!')
  })
  allProductsData = new Promise((resolve, reject) => {
    if (true) {
      resolve(allProductsData)
      return
    }
    reject('error, check the code!')
  })

  allKebabProductsData = new Promise((resolve, reject) => {
    if (true) {
      resolve(allKebabMeny)
      return
    }
    reject('error, check the code!')
  })

  allALaCarteProductsData = new Promise((resolve, reject) => {
    if (true) {
      resolve(allALaCarte)
      return
    }
    reject('error, check the code!')
  })

  allSaladProductsData = new Promise((resolve, reject) => {
    if (true) {
      resolve(allSallad)
      return
    }
    reject('error, check the code!')
  })


  getCategories = async () => {
    try {
      const result = await this.allCategoriesData
      this.setState({ allCategories: result })
    } catch (error) {
      console.log(error)
    }
  }

  getAllProducts = async () => {
    try {
      const result = await this.allProductsData
      this.setState({ allProducts: result })
    } catch (error) {
      console.log(error)
    }
  }

  getAllKebabProducts = async () => {
    try {
      const result = await this.allKebabProductsData
      this.setState({ allKebabProducts: result })
    } catch (error) {
      console.log(error)
    }
  }

  getAllALaCarteProducts = async () => {
    try {
      const result = await this.allALaCarteProductsData
      this.setState({ allALaCarteProducts: result })
    } catch (error) {
      console.log(error)
    }
  }

  getAllSaladProducts = async () => {
    try {
      const result = await this.allSaladProductsData
      this.setState({ allSaladProducts: result })
    } catch (error) {
      console.log(error)
    }
  }

  // HANDLE CHANGE
  /*******************************************************/
  changeCategory = async (newCategory) => {
    this.setState({ activeCategory: newCategory })
    this.getProductsByCategory(newCategory)
  }

  getProductsByCategory = async (category) => {
    let separateCategoriesByname = []
    //Separate arrays by category names
    const separateCategories = allProductsData.reduce(function (
      singleCategory,
      singleItem,
    ) {
      separateCategoriesByname = Object.keys(singleCategory)

      if (!singleCategory[singleItem.Category])
        singleCategory[singleItem.Category] = singleItem
      else
        singleCategory[singleItem.Category] = Array.isArray(
          singleCategory[singleItem.Category],
        )
          ? singleCategory[singleItem.Category].concat(singleItem)
          : [singleCategory[singleItem.Category]].concat(singleItem)
      return singleCategory
    },
      {})

    const result = Object.keys(separateCategories).map(
      (e) => separateCategories[e],
    )

    let singleCategoryArray = []
    result.map((categories) => {
      return singleCategoryArray.push(categories)
    })
    //Change products by category
    separateCategoriesByname.forEach((cate) => {
      if (cate === category) {
        return this.setState({ allProducts: separateCategories[category] })
      }
      if (category === 'Menu') {
        return this.setState({ allProducts: allProductsData })
      }
    })
  }

  showHiddenMenu() {
    const hiddenMenu = document.querySelector('.navigation-menu')
    hiddenMenu.classList.toggle('active')
  }
  removeNavigationMenu() {
    const hiddenMenu = document.querySelector('.navigation-menu')
    hiddenMenu.classList.remove('active')
  }

  removeMenu() {
    const hiddenMenu = document.querySelector('.menu')
    hiddenMenu.classList.remove('active')
  }
  findMenuItem(e) {
    e.preventDefault()
    const collectData = []
    allProductsData.map((product) => {
      if (product.ItemName.toLowerCase().includes(e.target.value)) {
        collectData.push(product)
        this.setState({ allProducts: [...collectData] })
      }
    })
    if (collectData.length === 0) {
      this.setState({ allProducts: [] })
    }
  }

  //! Other
  componentDidMount() {
    this.getCategories()
    this.getAllProducts()
    this.getAllKebabProducts()
    this.getAllALaCarteProducts()
    this.getAllSaladProducts()
    this.getProductsByCategory(this.state.activeCategory)
  }

  render() {
    return (
      <BrowserRouter>
        <Header
          removeNavigationMenu={this.removeNavigationMenu}
          handleValidation={this.handleValidation}
          productsQuantity={this.state.productsQuantity}
          showHiddenMenu={this.showHiddenMenu}
        />
        <Routes>
          <Route path="/" element={<RootSection />} />
          <Route
            path="/menu"
            element={
              <Menu
                findMenuItem={this.findMenuItem}
                allProducts={this.state.allProducts}
                allCategories={this.state.allCategories}
                changeCategory={this.changeCategory}
                handleAddProduct={this.handleAddProduct}
                handleRemoveProduct={this.handleRemoveProduct}
                activeCategory={this.state.activeCategory}
              />
            }
          />
          <Route path="/kebab" element={<Kebab
                findMenuItem={this.findMenuItem}
                allProducts={this.state.allKebabProducts}
                allCategories={this.state.allCategories}
                changeCategory={this.changeCategory}
                handleAddProduct={this.handleAddProduct}
                handleRemoveProduct={this.handleRemoveProduct}
                activeCategory={this.state.activeCategory} />} />
          <Route path="/a-la-carte" element={<ALaCarte
                findMenuItem={this.findMenuItem}
                allProducts={this.state.allALaCarteProducts}
                allCategories={this.state.allCategories}
                changeCategory={this.changeCategory}
                handleAddProduct={this.handleAddProduct}
                handleRemoveProduct={this.handleRemoveProduct}
                activeCategory={this.state.activeCategory} />} />
          <Route path="/sallader" element={<Salad
                findMenuItem={this.findMenuItem}
                allProducts={this.state.allSaladProducts}
                allCategories={this.state.allCategories}
                changeCategory={this.changeCategory}
                handleAddProduct={this.handleAddProduct}
                handleRemoveProduct={this.handleRemoveProduct}
                activeCategory={this.state.activeCategory} />} />
        </Routes>

        {/* <Footer /> */}
      </BrowserRouter>
    )
  }
}
