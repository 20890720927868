import { Link } from "react-router-dom";
import React from 'react'
import { motion } from "framer-motion";
import "react-alice-carousel/lib/alice-carousel.css";
import HeroVideo from '../../assets/images/hero/making-pizza.mp4';

const Hero = () => {
  return (
    <section className="hero-section">
      <video autoPlay loop muted playsInline className="hero-video" >
        <source src={HeroVideo} type="video/mp4" />
      </video>
      <section className="header-info flex-container flex-column txt-center pop-font txt-white">
        
          <span>Rondell pizzeria</span>
          <h1 className="txt-white">Upplev skillnaden</h1>
          <p className="txt-white">
            Att beställa har nu blivigt ännu lättare
          </p>
        

        <div className="header-btns flex-container flex-row">
          <a href="https://order.trueapp.se/rondell/store1/" className=" passive-button-style">
            Beställ online
          </a>
          <Link className=" passive-button-style  " to="/menu">
            Spana in våra pizzor
          </Link>
        </div>
      </section>

    </section>

  );
}

export default Hero;