import React from "react";

export default class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAttributes: [],
      allAttributesAreSelected: false,
    };
    this.handleSelectedAttributes = this.handleSelectedAttributes.bind(this);
  }

  //HANDLE ATTRIBUTES
  /*********************************************************************/
  handleSelectedAttributes = (attributeId, attributeValue) => {
    const newSelectedAttribute = { attributeId, attributeValue };

    let userSelectedAttributes = [...this.state.selectedAttributes];
    const existingAttribute = userSelectedAttributes.find(
      (attribute) => attribute.attributeId === newSelectedAttribute.attributeId
    );

    if (existingAttribute) {
      for (let i = 0; i < userSelectedAttributes.length; i++) {
        if (
          userSelectedAttributes[i].attributeId ===
          newSelectedAttribute.attributeId
        ) {
          userSelectedAttributes[i] = { ...newSelectedAttribute };
        }
      }
    } else {
      userSelectedAttributes.push(newSelectedAttribute);
    }
    this.setState({ selectedAttributes: userSelectedAttributes });
  };
  // Check if attributes are selected
  /*******************************************************************/
  handleAllAttributesAreSelected = async () => {
    this.setState({ allAttributesAreSelected: true });
  };
  // /********************************************************/
  // handleProductHasNoAttributes = async () => {
  //   if (this.props.singleProduct.attributes.length === 0) {
  //     this.handleAllAttributesAreSelected();
  //   }
  // };

  // RENDER & UPDATE
  /*********************************************************************/
  componentDidMount() {
    // this.handleProductHasNoAttributes();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.selectedAttributes !== nextState.selectedAttributes &&
      nextState.selectedAttributes.length ===
      this.props.singleProduct.attributes.length
    ) {
      this.handleAllAttributesAreSelected();
    }
    return true;
  }

  render() {
    const {
      singleProduct,
      previousCategory
    } = this.props;
    
    const isNewCategory = singleProduct.category !== previousCategory

    return (
      <div className="menu-item flex-container flex-column txt-white">
        {isNewCategory && 
        <div className="price-category">
          <p>{singleProduct.category}</p>
          {singleProduct.price && <p>Pris: {singleProduct.price}:-</p>}
          {singleProduct.familyPrice && <p>Familje: {singleProduct.familyPrice}:-</p>}
          {singleProduct.info && <p>{singleProduct.info}</p>}
          </div>
        }
        <h3>{String(singleProduct.index).padStart(2, '0')}. {singleProduct.value} {singleProduct.certainPrice && "( " + singleProduct.certainPrice +" )"}</h3>  
        <ul>
        {singleProduct?.ingredients?.map((ingredient) => {
          return <li>{ingredient.id.toUpperCase()}</li>
        })}
        </ul>
      
      </div>
    );
  }
}
