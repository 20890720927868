export const AllCategories = [
  {
    name: "Menu",
    id: "Menu",
  },
  {
    name: "Pizza",
    id: "Pizza",
  },
  {
    name: "Pasta",
    id: "Pasta",
  },
  {
    name: "Sushi",
    id: "Sushi",
  },
  {
    name: "Drinks",
    id: "Drinks",
  },
  {
    name: "Sale",
    id: "Sale",
  },
];
